import { SVGProps } from 'react'

import { SvgComponent } from '~/types/common-props'

interface SvgIconProps extends Omit<SVGProps<HTMLOrSVGElement>, 'children'> {
  Icon: SvgComponent | null
}

export const SvgIcon = ({ Icon, ...props }: SvgIconProps) =>
  Icon ? <Icon {...props} /> : null
