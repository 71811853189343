import {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'
import Rating, { RatingComponentProps } from 'react-rating'

import RatingEmpty from '~/assets/images/icons/star-empty.svg'
import RatingFull from '~/assets/images/icons/star-full.svg'
import { IS_BROWSER } from '~/constants/browser'
import { StrapiApi } from '~/services/Api'
import { RatingData } from '~/types/strapi-rest'
import { cx } from '~/utils'

import * as containerStyles from './ArticleRatingItem.module.scss'

interface ArticleRatingItemProps {
  data: RatingData
  article: number | null
  customRating: number
  setRefreshRatings: Dispatch<SetStateAction<boolean>>
}

const RatingComponent = Rating as unknown as FC<RatingComponentProps>

const ArticleRatingItem = ({
  data,
  article,
  customRating,
  setRefreshRatings,
}: ArticleRatingItemProps) => {
  const [rating, setRating] = useState<number | undefined>()
  const [activeStar, setActiveStar] = useState<number | null>(null)

  useEffect(() => {
    const value = localStorage.getItem(String(article))
    setRating(value ? Number(value) : undefined)
  }, [article])

  const { count, average } = data

  const handleClick = useCallback(
    async (value: number) => {
      try {
        await StrapiApi.setArticleRating({ score: value, article })

        setRating(value)
        setActiveStar(value)

        if (IS_BROWSER) {
          localStorage.setItem(String(article), String(value))
        }

        setRefreshRatings(true)
      } catch (error) {
        console.log('error', error)
      }
    },
    [article],
  )

  return (
    <div className={containerStyles.articleRating}>
      <div className={containerStyles.articleRating__title}>
        Rate this article!
      </div>
      <div className={rating ? 'article-rating__active' : 'article-rating'}>
        <RatingComponent
          start={0}
          stop={5}
          readonly={!!rating}
          initialRating={rating}
          emptySymbol={
            <img
              src={RatingEmpty}
              className={containerStyles.articleRating__star}
              alt="Rate this article | Codica"
              title="Rate this article"
              width="35"
              height="35"
              loading="lazy"
            />
          }
          fullSymbol={
            <img
              src={RatingFull}
              alt="Rate this article full | Codica"
              title="Rate this article full"
              className={cx(
                containerStyles.articleRating__star,
                activeStar ? 'rating-star__active' : 'rating-star',
              )}
              width="35"
              height="35"
              loading="lazy"
            />
          }
          onClick={handleClick}
        />
      </div>
      <div className={containerStyles.articleRating__label}>
        ({count + customRating} ratings, average: {average} out of 5)
      </div>
    </div>
  )
}

export default ArticleRatingItem
