import { useEffect, useState } from 'react'

import { ReactComponent as ArticleViewsIcon } from '~/assets/images/icons/article-show.svg'
import { SvgIcon } from '~/components/icons/SvgIcon'
import { StrapiApi } from '~/services/Api'
import { ArticleInfoProps } from '~/views/Blog/components/ArticleInfo/ArticleInfo.view'

import * as containerStyles from '../ArticleInfo/ArticleInfo.module.scss'

type ArticleViewsProps = Pick<
  ArticleInfoProps,
  'views' | 'articleId' | 'isArticle'
>

const ArticleViews = ({ views, articleId, isArticle }: ArticleViewsProps) => {
  const [articleViews, setArticleViews] = useState(views || 1500)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const setViews = async () => {
      try {
        if (isArticle) {
          const { data: incrementedViews } =
            await StrapiApi.incrementArticleViews(articleId)

          setArticleViews(incrementedViews)
        } else {
          const response = await StrapiApi.getArticleById(articleId)
          const data = response.data || {}
          const { views: strapiViews } = data?.attributes || {}
          const newViews = strapiViews || articleViews

          setArticleViews(newViews)
        }
      } catch (error) {
        console.error('error', error)
      } finally {
        setLoading(false)
      }
    }

    if (articleId) setViews()
  }, [articleId])

  return (
    <>
      <div
        className={containerStyles.articleInfo__item}
        style={{ minWidth: 100, minHeight: 26 }}
      >
        <SvgIcon
          Icon={ArticleViewsIcon}
          className={containerStyles.articleInfo__icon}
        />
        {!loading && `${articleViews} views`}
      </div>
    </>
  )
}

export default ArticleViews
