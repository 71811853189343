import { cx } from '~/utils'

import * as containerStyles from './ArticleSocialShare.module.scss'

const ArticleSocialShare = ({ title, url }) => {
  const windowShare = (e) => {
    const h = 500
    const w = 500
    const s = screen

    window.open(
      e.target.href,
      'windowNew',
      `scrollbars=1,height=${Math.min(h, s.availHeight)},width=${Math.min(
        w,
        s.availWidth,
      )},left=${Math.max(0, (s.availWidth - w) / 2)},top=${Math.max(
        0,
        (s.availHeight - h) / 2,
      )}`,
    )
  }

  return (
    <div className={containerStyles.socialShare}>
      <a
        target="_blank"
        aria-label="Facebook"
        rel="noopener noreferrer"
        href={`https://www.facebook.com/sharer/sharer.php?u=https://www.codica.com/blog/${url}/`}
        className={cx(
          containerStyles.socialShare__link,
          containerStyles.socialShare__link_facebook,
        )}
      />
      <a
        target="windowNew"
        aria-label="Twitter"
        rel="noopener noreferrer"
        onClick={(e) => windowShare(e)}
        href={`https://twitter.com/intent/tweet?text=${title}&url=https://www.codica.com/blog/${url}/&via=CodicaCom`}
        className={cx(
          containerStyles.socialShare__link,
          containerStyles.socialShare__link_twitter,
        )}
      />
      <a
        target="_blank"
        aria-label="Linkedin"
        rel="noopener noreferrer"
        href={`https://www.linkedin.com/sharing/share-offsite/?url=https://www.codica.com/blog/${url}/`}
        className={cx(
          containerStyles.socialShare__link,
          containerStyles.socialShare__link_linked,
        )}
      />
    </div>
  )
}

export default ArticleSocialShare
