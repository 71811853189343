import { lazy } from 'react'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import { ArticleIdProps } from '~/types/common-props'

import * as containerStyles from './ArticleComments.module.scss'

const ArticleComments = lazy(() => import('./ArticleComments.view'))

const ArticleCommentsContainer = (props: ArticleIdProps) => (
  <div className={containerStyles.comments}>
    <RenderWhenVisible
      fallback={<EmptyFallback minHeight={799} />}
      className="no-gutters"
      withSuspense
    >
      <ArticleComments {...props} />
    </RenderWhenVisible>
  </div>
)

export default ArticleCommentsContainer
