import { useEffect, useState } from 'react'

import { StrapiApi } from '~/services/Api'
import { RatingData } from '~/types/strapi-rest'

import ArticleRatingItem from './ArticleRatingItem'

interface ArticleRatingSectionProps {
  strapiId: number | null
  customRating: number
}

const ArticleRatingSection = ({
  strapiId,
  customRating,
}: ArticleRatingSectionProps) => {
  const [refreshRatings, setRefreshRatings] = useState(false)
  const [ratingData, setRatingData] = useState<RatingData>({
    average: 0,
    count: 0,
  })

  useEffect(() => {
    async function fetchData() {
      const result = await StrapiApi.getArticleById(strapiId)
      setRatingData(result.data.attributes.ratings)
    }

    fetchData().catch((error) => console.log('error', error))
  }, [strapiId, refreshRatings])

  return (
    <ArticleRatingItem
      data={ratingData}
      article={strapiId}
      customRating={customRating}
      setRefreshRatings={setRefreshRatings}
    />
  )
}

export default ArticleRatingSection
