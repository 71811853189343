import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { ReactNode } from 'react'

import { breakpointsOptions } from '../../../gatsby-plugins-options/breakpoints.options.mjs'

type Breakpoints = keyof typeof breakpointsOptions

type ResponsiveProps = {
  breakpoint: Breakpoints
  children: ReactNode
}

const Responsive = ({ children, breakpoint }: ResponsiveProps) => {
  const breakpoints = useBreakpoint()

  const isVisible = breakpoints[breakpoint]

  if (
    typeof window == 'undefined' ||
    typeof location === 'undefined' ||
    typeof document === 'undefined'
  ) {
    return null
  }

  if (!isVisible) {
    return null
  }

  return children
}

export default Responsive
