import { BreadcrumbsProps } from '~/components/breadcrumbs/Breadcrumbs/Breadcrumbs.view'

export const SchemaBreadcrumbs = ({
  current,
  currentPath,
  secondLevel,
  secondLevelPath,
  thirdLevel,
  thirdLevelPath,
}: BreadcrumbsProps) => {
  const itemListElement = [
    {
      '@type': 'ListItem',
      position: 1,
      name: 'Codica',
      item: 'https://www.codica.com/',
    },
    !secondLevel &&
      !thirdLevel && {
        '@type': 'ListItem',
        position: 2,
        name: current,
        item: `https://www.codica.com${currentPath}`,
      },
    secondLevel && {
      '@type': 'ListItem',
      position: 2,
      name: secondLevel,
      item: `https://www.codica.com${secondLevelPath}`,
    },
    secondLevel &&
      !thirdLevel && {
        '@type': 'ListItem',
        position: 3,
        name: current,
        item: `https://www.codica.com${currentPath}`,
      },
    thirdLevel && {
      '@type': 'ListItem',
      position: 3,
      name: thirdLevel,
      item: `https://www.codica.com${thirdLevelPath}`,
    },
    secondLevel &&
      thirdLevel && {
        '@type': 'ListItem',
        position: 4,
        name: current,
        item: `https://www.codica.com${currentPath}`,
      },
  ].filter(Boolean)

  const schema = {
    '@context': 'https://schema.org/',
    '@type': 'BreadcrumbList',
    itemListElement,
  }

  return JSON.stringify(schema)
}
