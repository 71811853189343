import * as containerStyles from './AuthorSocial.module.scss'

const AuthorSocial = ({ authorSlug }) => {
  const CEO = 'dmytro'
  const BusinessPartner = 'natalya'

  return (
    <>
      {authorSlug === CEO && (
        <div className={containerStyles.authorSocial__links}>
          <a
            target="_blank"
            aria-label="Twitter Dmytro"
            rel="noopener noreferrer nofollow"
            href="https://twitter.com/dmitry_chekalin"
            className={containerStyles.authorSocial__links_twitter}
          />
          <a
            target="_blank"
            aria-label="Linkedin Dmytro"
            rel="noopener noreferrer nofollow"
            href="https://www.linkedin.com/in/dmitrychekalin/"
            className={containerStyles.authorSocial__links_linkedin}
          />
        </div>
      )}

      {authorSlug === BusinessPartner && (
        <div className={containerStyles.authorSocial__links}>
          <a
            target="_blank"
            aria-label="Twitter Natalya"
            rel="noopener noreferrer nofollow"
            href="https://twitter.com/NatalyaKlimenko"
            className={containerStyles.authorSocial__links_twitter}
          />
          <a
            target="_blank"
            aria-label="Linkedin Natalya"
            rel="noopener noreferrer nofollow"
            href="https://www.linkedin.com/in/natalyaklimenko/"
            className={containerStyles.authorSocial__links_linkedin}
          />
        </div>
      )}
    </>
  )
}

export default AuthorSocial
