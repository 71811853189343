// extracted by mini-css-extract-plugin
export var recentArticles = "cB_s3";
export var recentArticles__category = "cB_tf";
export var recentArticles__img = "cB_s6";
export var recentArticles__link = "cB_s5";
export var recentArticles__post = "cB_s4";
export var recentArticles__postClamp = "cB_td";
export var recentArticles__postDate = "cB_s8";
export var recentArticles__postDescriprion = "cB_tc";
export var recentArticles__postInfo = "cB_s7";
export var recentArticles__postStats = "cB_s9";
export var recentArticles__postTitle = "cB_tb";
export var recentArticles__title = "cB_tg";