import { lazy } from 'react'

import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { cx } from '~/utils'

import 'react-toastify/dist/ReactToastify.min.css'

import * as containerStyles from './SubscribeFormLarge.module.scss'

const SubscribeForm = lazy(() => import('../SubscribeFormLarge/SubscribeForm'))

interface SubscribeFormLargeProps {
  title: string
  description?: string
}

const SubscribeFormLarge = ({
  title,
  description,
}: SubscribeFormLargeProps) => (
  <div className={cx(containerStyles.subscribe, 'subscribeFormLarge')}>
    <div className={containerStyles.subscribe__formWrapper}>
      <div className={containerStyles.subscribe__headWrapper}>
        <div className={containerStyles.subscribe__title}>{title}</div>
        {description && (
          <p className={containerStyles.subscribe__description}>
            {description}
          </p>
        )}
      </div>

      <RenderWhenVisible withSuspense>
        <ReCaptchaProvider>
          <SubscribeForm />
        </ReCaptchaProvider>
      </RenderWhenVisible>
    </div>
  </div>
)

export default SubscribeFormLarge
