import { Link } from 'gatsby'
import { Props } from 'html-react-parser/lib/attributes-to-props'

import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import { cx } from '~/utils'

import * as containerStyles from './ArticleQuestion.module.scss'

interface ArticleQuestionProps {
  attributes: Props
}

const ArticleQuestion = ({ attributes }: ArticleQuestionProps) => (
  <div className={containerStyles.articleQuestion}>
    <div className={containerStyles.articleQuestion__title}>
      {attributes && attributes.title}
    </div>
    <p className={containerStyles.articleQuestion__description}>
      {attributes && attributes.description}
    </p>
    <Link
      to="/contacts/"
      className={cx(
        containerStylesButtons.button,
        containerStylesButtons.button__red,
      )}
    >
      {attributes && attributes.buttonName}
    </Link>
  </div>
)

export default ArticleQuestion
