import { graphql, useStaticQuery } from 'gatsby'

import { PhotosQuery } from '~/types/graphql'

const useArticleCtaSidebarStaticQuery = () => {
  const query = useStaticQuery<PhotosQuery>(graphql`
    query {
      imageCTA: file(relativePath: { eq: "article/article-cta.png" }) {
        childImageSharp {
          gatsbyImageData(formats: [AUTO, WEBP], placeholder: BLURRED)
        }
      }
    }
  `)
  return query
}

export default useArticleCtaSidebarStaticQuery
