import { Link } from 'gatsby'
import { getSrc } from 'gatsby-plugin-image'
import { kebabCase } from 'lodash'

import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import { cx, fileToImageLikeData } from '~/utils'

import AuthorSocial from '../../../Author/components/AuthorSocial'

import * as containerStyles from './ArticleAuthorInfo.module.scss'

const ArticleAuthorInfo = ({ authors }) => {
  const CEO = 'dmytro'
  const BusinessPartner = 'natalya'

  return (
    <div className={containerStyles.articleAuthorInfoSection}>
      {authors.map(({ id, avatar, slug, name, position, description }) => (
        <div key={id} className={containerStyles.articleAuthorInfo}>
          <div className={containerStyles.articleAuthorInfo__flex}>
            <div className={containerStyles.articleAuthorInfo__avatar}>
              <img
                src={getSrc(fileToImageLikeData(avatar!.localFile))}
                alt={`${name} ${position} | Codica`}
                title={`${name} ${position}`}
                width="80"
                height="80"
                loading="lazy"
              />
            </div>

            <div>
              <div className={containerStyles.articleAuthorInfo__name}>
                {name}
              </div>
              <div className={containerStyles.articleAuthorInfo__position}>
                {position}
              </div>
            </div>
          </div>

          <div className={containerStyles.articleAuthorInfo__wrapper}>
            <div className={containerStyles.articleAuthorInfo__description}>
              {description}
            </div>
            {(slug === CEO || slug === BusinessPartner) && (
              <AuthorSocial authorSlug={slug} />
            )}
            <Link
              to={`/blog/author/${kebabCase(slug || '')}/`}
              className={cx(
                containerStylesButtons.button,
                containerStylesButtons.button__darkBlue,
                containerStyles.articleAuthorInfo__btn,
              )}
            >
              Authors details
            </Link>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ArticleAuthorInfo
