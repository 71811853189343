import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import parse, {
  attributesToProps,
  Element,
  HTMLReactParserOptions,
} from 'html-react-parser'
import { lazy, memo } from 'react'

import ContactUsLink from '~/components/forms/SubscribeArticleForm/ContactUs'
import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import ReCaptchaProvider from '~/components/shared/ReCaptchaProvider'
import { fileToImageLikeData } from '~/utils'
import ArticleQuestion from '~/views/Blog/views/Article/components/ArticleQuestion'

const SubscribeArticleForm = lazy(
  () => import('~/components/forms/SubscribeArticleForm'),
)
const imgFormats = ['jpeg', 'jpg', 'png', 'webp', 'avif', 'tif', 'tiff']

type ArticleHtmlToReactProps = {
  source: string
  images:
    | Queries.Maybe<readonly Queries.Maybe<Queries.STRAPI_ARTICLEBodyMedias>[]>
    | undefined
}

const ArticleHtmlToReact = ({ source, images }: ArticleHtmlToReactProps) => {
  let counter = 0
  const isImages = images?.length

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (domNode instanceof Element) {
        const firstChild = domNode.children?.[0]
        const isChildImg =
          firstChild instanceof Element && firstChild?.name === 'img'

        if (domNode.name === 'p' && isChildImg && isImages) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { src: _src, ...props } =
            attributesToProps(firstChild.attribs) || {}
          const image = images[counter]

          if (image && imgFormats.some((ext) => image.url?.endsWith(ext))) {
            const gatsbyImage = getImage(fileToImageLikeData(image.localFile))
            counter += 1
            return <GatsbyImage {...(props as any)} image={gatsbyImage} />
          }
        }
        if (domNode.name === 'iframe') {
          const props = attributesToProps(domNode.attribs) || {}
          const height =
            typeof props.height === 'string' ? props.height : 'auto'

          return (
            <RenderWhenVisible
              withSuspense
              fallback={<EmptyFallback height={height} />}
            >
              <iframe {...props} />
            </RenderWhenVisible>
          )
        }
        if (domNode.name === 'div') {
          if (
            domNode.attribs &&
            domNode.attribs.id === 'subscribe-form-container'
          ) {
            return (
              <RenderWhenVisible fallback={<EmptyFallback />}>
                <ReCaptchaProvider>
                  <SubscribeArticleForm />
                </ReCaptchaProvider>
              </RenderWhenVisible>
            )
          }
          if (
            domNode.attribs &&
            domNode.attribs.id === 'contact-form-container'
          ) {
            return <ContactUsLink />
          }
          if (domNode.attribs && domNode.attribs.id === 'question-container') {
            const props = attributesToProps(domNode.attribs) || {}

            return <ArticleQuestion attributes={props} />
          }
        }
      }
    },
  }

  return (
    <div className="toc-content">
      {parse(source, { ...options, trim: true })}
    </div>
  )
}

export default memo(ArticleHtmlToReact)
