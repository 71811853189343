import { graphql } from 'gatsby'

import {
  AllStrapiArticle,
  AllStrapiCategory,
  StrapiArticle,
} from '~/types/graphql'
import { PagePropsWithContext } from '~/types/helper'
import { BlogPageContext } from '~/types/page-contex'
import ArticleView from '~/views/Blog/views/Article/Article.view'
import ArticleMeta from '~/views/Blog/views/Article/components/ArticleMeta'

import '~/views/Blog/views/Article/styles/index.scss'

import 'prismjs/themes/prism.min.css'

export type BlogArticlePageQueryResult = StrapiArticle &
  AllStrapiCategory &
  AllStrapiArticle

export const Head = ({
  data,
  pageContext: { ratingData },
}: PagePropsWithContext<BlogArticlePageQueryResult, BlogPageContext>) => (
  <ArticleMeta data={data} ratingData={ratingData} />
)

const BlogArticlePage = ({
  data,
  pageContext,
}: PagePropsWithContext<BlogArticlePageQueryResult, BlogPageContext>) => (
  <ArticleView data={data} pageContext={pageContext} />
)

export default BlogArticlePage

export const query = graphql`
  query ArticleContent($url: String) {
    strapiArticle(url: { eq: $url }) {
      tags {
        id
        name
        url
      }
      articlefaqs {
        answer {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        question
        createdAt
      }
      body {
        data {
          childMarkdownRemark {
            rehypeHtml
            timeToRead
          }
        }
        medias {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
          url
        }
      }
      lead_form
      structured_data
      structured_data_video
      id
      rating
      strapi_id
      url
      meta_description
      meta_title
      og_description
      og_image {
        url
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      preview_image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      twitter_card
      twitter_image {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      og_title
      authors {
        id
        name
        slug
        position
        description
        avatar {
          url
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 75
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                quality: 100
              )
            }
          }
        }
      }
      category {
        name
      }
      views
      title
      publication_date
      modified_date
      updatedAt
      related_posts {
        id
        url
        modified_date
        meta_description
        preview_image_alt
        body {
          data {
            childMarkdownRemark {
              timeToRead
            }
          }
        }
        preview_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                formats: [AUTO, WEBP]
                quality: 100
                placeholder: BLURRED
              )
            }
          }
        }
        title
        category {
          id
        }
      }
    }

    allStrapiCategory {
      nodes {
        name
        id
      }
    }

    allStrapiArticle(
      limit: 3
      sort: { modified_date: DESC }
      filter: { active: { eq: true }, published: { eq: true } }
    ) {
      edges {
        node {
          url
          title
          preview_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  formats: [AUTO, WEBP]
                  quality: 100
                  placeholder: BLURRED
                )
              }
            }
          }
          preview_image_alt
          category {
            name
            id
          }
          meta_description
          modified_date
          body {
            data {
              childMarkdownRemark {
                timeToRead
              }
            }
          }
        }
      }
    }
  }
`
