import { ReactComponent as ArticleModifiedDateIcon } from '~/assets/images/icons/article-calendar.svg'
import { ReactComponent as ArticleTimeReadingIcon } from '~/assets/images/icons/article-clock.svg'
import { SvgIcon } from '~/components/icons/SvgIcon'
import { ArticleIdProps } from '~/types/common-props'
import ArticleViews from '~/views/Blog/components/ArticleViews'

import * as containerStyles from './ArticleInfo.module.scss'

export interface ArticleInfoProps extends ArticleIdProps {
  modifiedDate: string | null
  views: number | null
  stats?: number | null
  isArticle?: boolean
}

const ArticleInfo = ({
  modifiedDate,
  stats,
  views,
  articleId,
  isArticle,
}: ArticleInfoProps) => (
  <div className={containerStyles.articleInfo} id="scrollToViews">
    <div className={containerStyles.articleInfo__item}>
      <SvgIcon
        Icon={ArticleModifiedDateIcon}
        className={containerStyles.articleInfo__icon}
      />
      {modifiedDate}
    </div>

    <div className={containerStyles.articleInfo__item}>
      <SvgIcon
        Icon={ArticleTimeReadingIcon}
        className={containerStyles.articleInfo__icon}
      />
      {`${Math.ceil(stats || 0)} min`}
    </div>

    <ArticleViews views={views} articleId={articleId} isArticle={isArticle} />
  </div>
)

export default ArticleInfo
