import { ReactNode } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

import Button from '~/components/buttons/Button'

function ErrorFallback({ resetErrorBoundary }, isCentered) {
  return (
    <div
      role="alert"
      style={{ maxWidth: isCentered ? 440 : '100%', margin: '0 auto' }}
    >
      <h4>Something went wrong:</h4>
      <p>Please reload the page or click on the Try again button</p>

      {resetErrorBoundary && (
        <div style={{ marginBottom: '20px' }}>
          <Button
            isRed
            type="button"
            name="Try again"
            onClick={resetErrorBoundary}
          />
        </div>
      )}
    </div>
  )
}

interface MyErrorBoundaryProps {
  isCentered?: boolean
  children?: ReactNode
}

const MyErrorBoundary = ({ isCentered, children }: MyErrorBoundaryProps) => (
  <ErrorBoundary FallbackComponent={(e) => ErrorFallback(e, isCentered)}>
    {children}
  </ErrorBoundary>
)

export default MyErrorBoundary
