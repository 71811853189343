import { lazy, Suspense } from 'react'

import Responsive from '~/components/shared/Responsive'

const ArticleSideNavigation = lazy(() => import('./ArticleSideNavigation'))

const ArticleLazySideNavigation = (props) => (
  <Responsive breakpoint="mdUp">
    <Suspense>
      <ArticleSideNavigation {...props} />
    </Suspense>
  </Responsive>
)

export default ArticleLazySideNavigation
