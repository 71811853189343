import { Link } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import { BlogArticlePageQueryResult } from '~/templates/blog/Article'
import { convertInDateWithoutTime, cx } from '~/utils'

import * as containerStyles from './ArticleRelatedPosts.module.scss'

interface ArticleRelatedPostsProps {
  data: BlogArticlePageQueryResult
}

const ArticleRelatedPosts = ({ data }: ArticleRelatedPostsProps) => (
  <div className={containerStyles.recentArticles}>
    <div className="container container-md">
      <RenderWhenVisible
        className="row"
        fallback={<EmptyFallback minHeight={350} className="row" />}
      >
        {data.strapiArticle?.related_posts &&
          data.strapiArticle?.related_posts.map((article) => {
            if (article?.id === data.strapiArticle?.id) return null

            const modifiedDate = convertInDateWithoutTime(
              article!.modified_date as string,
            )

            return (
              <div className="col-12 col-md-6 col-lg-4" key={article!.id}>
                <div className={containerStyles.recentArticles__post}>
                  <Link
                    title={article?.title || ''}
                    to={`/blog/${article?.url}/`}
                    className={containerStyles.recentArticles__link}
                  />

                  <div className={containerStyles.recentArticles__img}>
                    <GatsbyImage
                      image={
                        getImage(
                          article!.preview_image!
                            .localFile as unknown as IGatsbyImageData,
                        ) as IGatsbyImageData
                      }
                      alt={`Article: ${article?.preview_image_alt}` || ''}
                      title={article?.preview_image_alt || ''}
                    />
                  </div>

                  <div className={containerStyles.recentArticles__postInfo}>
                    <div
                      className={containerStyles.recentArticles__postStats}
                    >{`${Math.ceil(
                      article?.body?.data?.childMarkdownRemark?.timeToRead || 0,
                    )} min`}</div>

                    <div className={containerStyles.recentArticles__postDate}>
                      {modifiedDate}
                    </div>
                  </div>

                  <div
                    className={cx(
                      containerStyles.recentArticles__postTitle,
                      containerStyles.recentArticles__postClamp,
                    )}
                  >
                    {article?.title}
                  </div>

                  <div
                    className={cx(
                      containerStyles.recentArticles__postDescriprion,
                      containerStyles.recentArticles__postClamp,
                    )}
                  >
                    {article?.meta_description}
                  </div>

                  {data.allStrapiCategory.nodes.map((categories) => {
                    if (categories.id === article?.category?.id) {
                      return (
                        <div
                          className={containerStyles.recentArticles__category}
                          key={categories.id}
                        >
                          {categories.name}
                        </div>
                      )
                    }
                    return null
                  })}
                </div>
              </div>
            )
          })}
      </RenderWhenVisible>
    </div>
  </div>
)

export default ArticleRelatedPosts
