import { Link } from 'gatsby'
import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image'

import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import { cx, fileToImageLikeData } from '~/utils'

import * as containerStyles from './ArticleCtaSidebar.module.scss'
import useArticleCtaSidebarStaticQuery from './useArticleCtaSidebarStaticQuery'

const ArticleCtaSidebar = () => {
  const query = useArticleCtaSidebarStaticQuery()
  const imageCTA = getImage(fileToImageLikeData(query.imageCTA))

  return (
    <div className={containerStyles.aricleCtaSidebar}>
      <GatsbyImage
        image={imageCTA as IGatsbyImageData}
        alt="Article contents cta | Codica"
        title="Article contents cta"
        loading="lazy"
        className={containerStyles.aricleCtaSidebar__image}
      />
      <div className={containerStyles.aricleCtaSidebar__title}>
        Where Ideas Thrive
      </div>
      <div className={containerStyles.aricleCtaSidebar__description}>
        Bring your vision to life with a highly engaging and unique web
        application that your users love.
      </div>
      <Link
        to="/contacts/"
        className={cx(
          containerStylesButtons.button,
          containerStylesButtons.button__red,
          containerStyles.aricleCtaSidebar__btn,
        )}
      >
        Learn more
      </Link>
    </div>
  )
}

export default ArticleCtaSidebar
