import { Link } from 'gatsby'

import * as containerStylesButtons from '~/components/buttons/Button/Button.module.scss'
import { cx } from '~/utils'

const ContactUsLink = () => (
  <Link
    to="/contacts/"
    className={cx(
      containerStylesButtons.button,
      containerStylesButtons.button__red,
    )}
  >
    Contact us
  </Link>
)

export default ContactUsLink
